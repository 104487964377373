import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { config } from './config';
//import { AlertService } from './alert/alert.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    //public alertService: AlertService
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!this.isErrorMessageSuppressed(error)) {
          this.showErrorMessage(error);
        }
        return throwError(error);
      }));
  }

  private isErrorMessageSuppressed (error: HttpErrorResponse) {
    return this.isQueryForLoggedUser(error);
      // this.isOtpRequired(error);
  }

  // when Node backend is not running, then logged user query fails; let's not show the error
  private isQueryForLoggedUser(error: HttpErrorResponse) {
    return error.url.endsWith(`${config.authApiUrl}/PersonalInformation`);
  }

  // private isOtpRequired(error: HttpErrorResponse) {
  //   return error.error.msg === 'OTP_REQUIRED';
  // }

  private showErrorMessage(error: HttpErrorResponse) {
    // this.snackBar.openFromComponent(SnackBarComponent, {
    //   duration: 3000,
    // });
    let data = error.error ?? 'Unknown error'

    //this.alertService.error(data)
  }

}
