import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { LoginRequest } from '../models/loginRequest';
import { AuthStrategy, AUTH_STRATEGY } from './auth.strategy';
import { config } from '../../shared/config';
import { Role } from '../models/types';
import { CacheService } from '@app/shared/cache.service';
import { ForgotPasswordRequest } from '../models/forgotPasswordRequest';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public readonly INITIAL_PATH = '/my-application';
  public readonly OPEN_MEMBER_PATH = '/my-application';
  public readonly MEMBER_PATH = '/my-application';
  public readonly LOGIN_PATH = '/sign-in';
  public readonly CONFIRM_PATH = '/confirm';

  constructor(
    private http: HttpClient,
    public router: Router,
    private cacheService: CacheService,
    @Inject(AUTH_STRATEGY) private auth: AuthStrategy<any>
    ) {}

  getInitialPathForRole(role: Role): string {
    return role === 'ACTIVE' ? this.MEMBER_PATH : this.OPEN_MEMBER_PATH;
  }
  // getCurrentPathForRole(): string {
  //   let role = "";
  //   this.getUserRole$().subscribe(
  //     role => {
  //       role = role;
  //     });
  //     return role === 'ACTIVE' ? this.MEMBER_PATH : this.OPEN_MEMBER_PATH;

  // }

  signup(user: User): Observable<User> {
    return this.http.post<any>(config.apiUrl+'/Signup', user)
    .pipe(tap(data => this.auth.doLoginUser(data)));
  }
  requestForgotPassword(ForgotPasswordInformationBody): Observable<ForgotPasswordRequest>{
    return this.http.post<any>(config.apiUrl+'/SendPasswordResetLink', ForgotPasswordInformationBody)
    .pipe(tap(data => this.auth.doLoginUser(data)));
  }

  confirm(email: string, code: string): Observable<void> {
    return this.http.post<any>(config.apiUrl+'/confirm?', {email, code});
  }

  login(loginRequest: LoginRequest): Observable<User> {
    return this.http.post<any>(config.apiUrl+'/Login', loginRequest)
      .pipe(tap(data => this.auth.doLoginUser(data)));
  }

  logout() {
    return this.http.get<any>(config.apiUrl+'/Logout')
      .pipe(tap(() => this.doLogoutUser()));
  }

  isLoggedIn$(): Observable<boolean> {
    return this.auth.getCurrentUser().pipe(
      map(user => !!user),
      catchError(() => of(false))
    );
  }

  getCurrentUser$(): Observable<User> {
    return this.auth.getCurrentUser();
  }

  getEmailAddress$(): Observable<string> {
    return this.auth.getCurrentUser().pipe(
      map(user => user.emailAddress)
    );
  }
  getUserRole$(): Observable<string> {
    return this.auth.getCurrentUser().pipe(
      map(user => user.role)
    );
  }

  getFirstName$(): Observable<string> {
    return this.auth.getCurrentUser().pipe(
      map(user => user.firstName)
    );
  }

  getUsername$(): Observable<string> {
    return this.auth.getCurrentUser().pipe(
      map(user => user.emailAddress)
    );
  }

  getMemberNo$(): Observable<string> {
    return this.auth.getCurrentUser().pipe(
      map(user => user.memberNo)
    );
  }

  doLogoutAndRedirectToLogin() {
    this.doLogoutUser();
    this.router.navigate(['/sign-in']);
  }

  // logoutAuth0() {
  //   return this.logout().subscribe(() => {
  //     window.location.href = `${auth0.url}/logout?client_id=${auth0.clientId}&returnTo=${auth0.returnUrl}`;
  //   });
  // }

  // isAuth0User(user: User): boolean {
  //   return user.id.startsWith('auth0');
  // }

  private doLogoutUser() {
    this.cacheService.pruneAll();
    this.auth.doLogoutUser();
  }
}
