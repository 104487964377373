import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from '../services/auth.service';
import { PasswordService } from '../services/password.service.spec';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isRequestSent: boolean;
  public recoveryForm: FormGroup;  loading = false;
  submitted = false;
  error = '';

  constructor(private router: Router, private route: ActivatedRoute,private formBuilder: FormBuilder,public alertService: ToastrService,

    private passwordService: PasswordService) { }

	// On SignIn link click
	onSignIn() {
	  this.router.navigate(['sign-in'], { relativeTo: this.route.parent });
	}


  ngOnInit(): void {
    this.isRequestSent = false;
    this.recoveryForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required]]
    });
  }
  get f() {
     return this.recoveryForm?.controls;
     }
  recover() {
    this.loading = true;
    this.passwordService.requestRecovery(this.f.emailAddress.value)
    .subscribe({
      next :(res) =>{
        if(res.responseCode){
          this.alertService.success(res.responseDescription);
             this.loading = false;
        }else{
          this.error = res.responseDescription;
          this.loading = false;
          // this.alertService.error(user.responseDescription);
        }
    },
    error: error =>{
      this.error = error.error.responseDescription ?? "Try again later";
      this.loading = false;
    }

    });
  }
  dismissAlert() {
    this.error = null;
  }

}
