	<!-- wrapper -->
	<div class="wrapper">
    <div class="authentication-reset-password d-flex align-items-center justify-content-center">
      <div class="row">
        <div class="col-12 col-lg-10 mx-auto">
          <div class="card">
            <div class="row g-0">
              <div class="col-lg-5 border-end">
                <div class="card-body">
                  <div class="p-5">
                    <div class="text-start">
                      <img src="assets/images/logo-img.png" width="180" alt="">
										</div>
                    <ng-container *ngIf="!isFinished; else finished">
										<h4 class="mt-5 font-weight-bold" style="color: #FF0000;">Generate New Password</h4>
										<p class="text-muted">We received your reset password request. Please enter your new password!</p>
                    <form [formGroup]="passwordForm">
										<div class="mb-3 mt-5">
											<label class="form-label">New Password</label>
											<input type="password" class="form-control" formControlName="password" placeholder="Enter new password" />
                      <div *ngIf="f.password.touched && f.password.invalid" class="text-danger">
                        <div *ngIf="f.password.errors?.required">Password is required.</div>
                        Input 8 Character Password
                      </div>
										</div>
										<div class="mb-3">
											<label class="form-label">Confirm Password</label>
											<input type="password" class="form-control" formControlName="confirmPassword" placeholder="Confirm password" />
                      <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid" class="text-danger">
                        <div *ngIf="f.confirmPassword?.errors?.required">Password is required.</div>
                        <div *ngIf="f.confirmPassword?.errors?.confirmedValidator">Password and Confirm Password must be match.</div>
                      </div>
										</div>
										<div class="d-grid gap-2">
											<button type="button" class="btn btn-primary" (click)="setPassword()" [disabled]="!passwordForm.valid">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Change Password
                      </button>
                      <a href="javascript:;" (click)="onLogin()" class="btn btn-light">
                        <i class='bx bx-arrow-back mr-1'></i>Back to Login
                      </a>
										</div>
                    </form>
                  </ng-container>
                  <ng-template #finished>
                    <div class="header">Your password is set up!</div>
                    <a href="javascript:;" (click)="onLogin()" class="btn btn-light"><i class='bx bx-arrow-back mr-1'></i>Back to Login</a>
                  </ng-template>
									</div>
								</div>
							</div>
							<div class="col-lg-7">
								<img src="assets/images/login-images/forgot-password-frent-img.jpg" class="card-img login-img h-100" alt="...">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end wrapper -->
