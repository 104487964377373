<div class="bg-lock-screen">
	<!-- wrapper -->
	<div class="wrapper">
		<div class="authentication-lock-screen d-flex align-items-center justify-content-center">
			<div class="card shadow-none bg-transparent">
				<div class="card-body p-md-5 text-center">
					<h2 class="text-white">10:53 AM</h2>
					<h5 class="text-white">Tuesday, January 14, 2021</h5>
					<div class="">
						<img src="assets/images/icons/user.png" class="mt-5" width="120" alt="" />
					</div>
					<p class="mt-2 text-white">Administrator</p>
					<div class="mb-3 mt-3">
						<input type="password" class="form-control" placeholder="Password" />
					</div>
					<div class="d-grid">
						<button type="button" class="btn btn-white">Login</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end wrapper -->
</div>